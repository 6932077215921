import axios from 'axios';

//demo marketplace
// export const API_URL = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/'; 
// export const API_URL = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/mobile/'; 

//demo marketplace
// const API_URL = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/web/';
// const API_URL2 = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/mobile/';


//ppd Dev
// const API_URL = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/';
//ppd Prod
const API_URL = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/';

export function Get(table, id, cb) {
	console.log(table);
	if (id === null) {
		axios
			.get(`${API_URL + table}`)
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`)
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}


export function Delete(table, id, cb) {
	axios
		.delete(`${API_URL + table}/${id}`)
		.then((response) => response.status)
		.then((status) => {
			cb(status);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((response) => {
			cb(response);
		});
}

export function PostStatus(table, id, data, cb) {
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}
export default API_URL;
