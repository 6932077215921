import React, { Component } from 'react';

// Import authService
import { DataToken } from '../../../function/Format';
import { Redirect } from 'react-router-dom';

class UserMenu extends Component {
	state = {
		navigate: false,
		// akun: DataToken(localStorage.getItem('access_token')),
		akun: ''
	};

	logOut(e) {
		e.preventDefault();
		localStorage.removeItem('access_token');
		this.setState({ navigate: true });
	}

	componentDidMount() {
		console.log(localStorage.getItem('access_token'));
		if (localStorage.getItem('access_token')) {
			this.setState({ akun: DataToken(localStorage.getItem('access_token')) });
		}
	}

	render() {
		const { navigate, akun } = this.state;
		if (navigate) {
			return <Redirect to="/" push={true} />;
		}

		return (
			<li className="onhover-dropdown">
				<div className="media  align-items-center">
					<img
						className="align-self-center pull-right mr-2"
						src={require('../../../assets/images/dashboard/user.png')}
						alt="header-user"
					/>
					<div className="media-body">
						<h6 className="m-0 txt-dark f-16">
							My Account
							<i className="fa fa-angle-down pull-right ml-2" />
						</h6>
					</div>
				</div>
				<ul className="profile-dropdown onhover-show-div p-20">
					<li>
						<a href="#" onClick={this.logOut.bind(this)}>
							<i className="icon-power-off" />
							Logout
						</a>
					</li>
				</ul>
			</li>
		);
	}
}

export default UserMenu;
